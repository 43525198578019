import { Accessor, createEffect, createSignal, JSX, onMount } from "solid-js";
import { giftUnwrap, ribbonConfetti } from "~/assets/assets";
import { Spacer } from "~/widgets/spacer";
import styles from "./gift_container.module.scss";
import { classNames } from "~/utils/etc";
import { HubbleEvent, webEventManager } from "~/data/events";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";
import { BorderGradient, Card } from "./gift_card";

export type GiftContainerProps = {
  brand: BrandDetailResponse;
  voucher?: VoucherDetailResponse;
  amount: number;
  isPreview?: boolean;
  description: string;
  getGiftVoucherSectionComponent?: () => JSX.Element;
  onClickUnwrapGift: () => void;
  onUnwrapAnimationComplete: () => void;
  defaultEventProps?: Record<string, any>;
};

export function GiftContainer(props: GiftContainerProps) {
  const [isGiftOpened, setIsGiftOpened] = createSignal(false);
  const [hasUnwrapLottiePlayed, setHasUnwrapLottiePlayed] = createSignal(false);
  const [hideUnwrapButton, setHideUnwrapButton] = createSignal(false);
  const [hideSenderName, setHideSenderName] = createSignal(false);
  const [animateDescription, setAnimateDescription] = createSignal(false);
  const [startInfiniteBounce, setStartInfiniteBounce] = createSignal(false);
  const [highlightCardDetails, setHighlightCardDetails] = createSignal(false);

  onMount(() => {
    const giftUnwrapElement = document.getElementById("gift-unwrap");
    const ribbonConfettiElement = document.getElementById("ribbon-confetti");
    giftUnwrapElement?.addEventListener(
      "complete",
      async () => {
        setAnimateDescription(true);
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setHasUnwrapLottiePlayed(true);
        try {
          // @ts-ignore
          ribbonConfettiElement?.play();
        } catch (e) {
          console.error(e);
        }
        await new Promise((resolve) => setTimeout(resolve, 400));
        setStartInfiniteBounce(true);
        setHighlightCardDetails(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        props.onUnwrapAnimationComplete();
      },
      { once: true }
    );
  });

  async function onClickUnwrapGift() {
    webEventManager.sendEvent(HubbleEvent.CLICK_UNWRAP_GIFT, {
      ...props.defaultEventProps,
    });
    setIsGiftOpened(true);
    try {
      const giftUnwrapElement = document.getElementById("gift-unwrap");
      // @ts-ignore
      giftUnwrapElement?.play();
    } catch (e) {
      console.error(e);
    }
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setHideSenderName(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    setHideUnwrapButton(true);
    props.onClickUnwrapGift();
  }

  return (
    <div class="relative  w-full max-w-[390px] font-['Inter'] ">
      <div
        classList={{
          [styles.giftContainerSlideUp]: hasUnwrapLottiePlayed(),
          "overflow-hidden": !hasUnwrapLottiePlayed(),
        }}
        class={classNames(
          "flex max-h-screen w-full translate-y-[100px] flex-col items-center justify-start "
        )}
      >
        <div class="relative flex w-full flex-col items-center  ">
          <div class="pointer-events-none left-0 right-0 h-[220px] w-[340px] translate-y-[20px] ">
            <GiftUnwrapLottie />
          </div>
          <div
            class="absolute bottom-[-130px] left-0  right-0 z-10 px-[50px] "
            classList={{
              hidden: hideUnwrapButton(),
            }}
          >
            <div
              class="flex justify-center text-center text-h3 font-medium transition-all duration-300"
              classList={{
                "opacity-0": hideSenderName(),
              }}
            >
              You received a gift
            </div>
            <Spacer height={24} />
            <UnwrapGiftButton />
          </div>
        </div>
        <DescriptionText text={props.description} />
        <div class="h-8"> &nbsp;</div>
        <AnimatedGiftCard
          brand={props.brand}
          voucher={props.voucher}
          amount={props.amount}
          isPreview={props.isPreview}
          rotateCard={hasUnwrapLottiePlayed}
          startInfiniteBounce={startInfiniteBounce}
          highlightCardDetails={highlightCardDetails}
          getGiftVoucherSectionComponent={props.getGiftVoucherSectionComponent}
          defaultEventProps={props.defaultEventProps}
        />
      </div>
      <div class="pointer-events-none absolute left-0 right-0 top-0 flex scale-150 ">
        <RibbonConfettiLottie />
      </div>
    </div>
  );

  function UnwrapGiftButton() {
    return (
      <div
        onClick={onClickUnwrapGift}
        class="flex cursor-pointer justify-center rounded-lg bg-white  py-[13px] text-[13px] font-semibold text-black transition-all duration-300 "
        classList={{
          "scale-y-[2%]": isGiftOpened(),
          "scale-x-0": hideSenderName(),
        }}
      >
        <div
          classList={{
            "opacity-0": isGiftOpened(),
          }}
        >
          Yay! Unwrap my gift
        </div>
      </div>
    );
  }

  function GiftUnwrapLottie() {
    return (
      <lottie-player
        id="gift-unwrap"
        src={giftUnwrap}
        autoplay={false}
        loop={false}
      ></lottie-player>
    );
  }

  function RibbonConfettiLottie() {
    return (
      <lottie-player
        id="ribbon-confetti"
        src={ribbonConfetti}
        autoplay={false}
        loop={false}
      ></lottie-player>
    );
  }

  function DescriptionText({ text }: { text: string }) {
    return (
      <div
        classList={{
          [styles.descriptionText]: animateDescription(),
          "opacity-0 flex justify-center": true,
        }}
      >
        <p
          class={classNames("max-w-[85%] text-center text-[24px] font-medium")}
        >
          {text}
        </p>
      </div>
    );
  }
}

export type AnimatedGiftCardProps = {
  brand: BrandDetailResponse;
  voucher?: VoucherDetailResponse;
  amount: number;
  isPreview?: boolean;
  getGiftVoucherSectionComponent?: () => JSX.Element;
  defaultEventProps?: Record<string, any>;
  rotateCard: Accessor<boolean>;
  startInfiniteBounce: Accessor<boolean>;
  highlightCardDetails: Accessor<boolean>;
};

export function AnimatedGiftCard(props: AnimatedGiftCardProps) {
  return (
    <div
      classList={{
        [styles.cardInfinteBounce]: props.startInfiniteBounce(),
        [styles.cardRotate]: props.rotateCard(),
      }}
      style={{
        perspective: "900px",
      }}
      class="h-[430px] w-full "
    >
      <div
        class={`flex w-full max-w-[432px] flex-col items-center justify-center px-[32px] ${styles.card}`}
      >
        <BorderGradient>
          <Card
            brand={props.brand}
            voucher={props.voucher}
            amount={props.amount}
            isPreview={props.isPreview}
            getGiftVoucherSectionComponent={
              props.getGiftVoucherSectionComponent
            }
            highlightCardDetails={props.highlightCardDetails}
            defaultEventProps={props.defaultEventProps}
          />
        </BorderGradient>
      </div>
    </div>
  );
}
